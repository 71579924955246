import { OnInit, Component} from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuService } from './service/app.menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  styles: [
    `
      .layout-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-top: 1em;
      }

      h6 {
        font-weight: bold;
        padding-top: 1em;
        padding-bottom: 0 !important;
      }

      .menu-item {
        position: relative;
      }

      .menu-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0px 16px;
        width: 100%;
      }

      .menu-item-header span {
        flex-grow: 1;
      }

      .submenu {
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 20px;

        max-height: 0;
        overflow: hidden;

        transition: max-height 0.4s ease-in-out;
      }

      .submenu-item {
        padding: 0px 0;
      }

      .submenu-item a {
        display: flex;
        align-items: center;
      }

      .submenu-item i {
        margin-right: 8px;
      }

      .pi-chevron-down,
      .pi-chevron-right {
        font-size: 18px;
        margin-left: 8px;
        transition: transform 0.3s ease;
      }

      .menu-item-header .chevron {
        padding: 0 !important;
        margin-left: auto;
      }

      .menu-item.expanded .submenu {
        max-height: 500px;
        padding-top: 0px;
      }
    `,
  ],
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  dbReportsItems: any[] = [];

  constructor(
    public layoutService: LayoutService,
    public menuService: MenuService,
  ) {}

  ngOnInit() {
    this.model = this.menuService.menuModel;
  }

  toggleSubmenu(item: any) {
    item.expanded = !item.expanded;
  }
}
