import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CommandExecuteEvent,
  ConversationalUIModule,
  Message,
  PromptCommand,
  PromptOutput,
  PromptRequestEvent,
  SendMessageEvent,
  User,
} from '@progress/kendo-angular-conversational-ui';
import { Observable, Subject, merge, from, map, scan } from 'rxjs';
import { ChatbotService } from 'src/app/services/chatbot.service';

@Component({
  selector: 'app-chatbot',
  standalone: true,
  imports: [
    CommonModule,
    ConversationalUIModule,
  ],
  templateUrl: './chatbot.component.html',
  styleUrl: './chatbot.component.scss'
})
export class ChatbotComponent {

  public feed: Observable<any>;

  public readonly user: User = {
    id: 1,
  };

  public readonly bot: User = {
    id: 0,
  };

  public promptOutputs: PromptOutput[] = [];
  public activeView: number = 0;
  public idCounter = 0;

  private local: Subject<Message> = new Subject<Message>();

  constructor(private svc: ChatbotService) {
    const messageSuggestions: Message = {
      author: this.bot,
      suggestedActions: [
        {
          type: "reply",
          value: "Hello!",
        },
        {
          type: "reply",
          value: "How can I create a partner?",
        },
      ],
      timestamp: new Date(),
      text: "Hello, it's CentBot! How can I help you?",
    };

    this.feed = merge(
      from([messageSuggestions]),
      this.local,
      this.svc.responses.pipe(
        map(
          (response): Message => ({
            author: this.bot,
            text: response,
          })
        )
      )
    ).pipe(scan((acc: Message[], x: Message) => [...acc, x], []));

    // Patch Lambda cold start
    this.svc.sendQuestion("Starting...").subscribe((response) => {})
  }

  public sendMessage(e: SendMessageEvent): void {
    // Emit the user's message to the feed
    this.local.next(e.message);

    // Show typing indicator from bot
    this.local.next({
      author: this.bot,
      typing: true,
    });

    this.svc.sendQuestion(e.message.text!).subscribe(
      (response) => {
        this.local.next({
          author: this.bot,
          text: response.response,
        });
      },
      (error) => {
        this.local.next({
          author: this.bot,
          text: "An error occurred. Please try again.",
        });
      }
    );
  }

  public onPromptRequest(ev: PromptRequestEvent): void {
    if (!ev.prompt) {
      return;
    }
    this.createPromptOutput(ev);
    this.activeView = 1;
  }

  public onCommandExecute(ev: CommandExecuteEvent): void {
    this.createPromptOutput(ev);
    this.activeView = 1;
  }

  private createPromptOutput(
    ev: PromptRequestEvent | CommandExecuteEvent
  ): void {
    this.idCounter += 1;
    const newOutput = {
      title: ev.isRetry ? "Retry test title" : "Test title",
      id: this.idCounter,
      prompt: (ev as PromptRequestEvent).prompt
        ? (ev as PromptRequestEvent).prompt
        : (ev as CommandExecuteEvent).command.text,
      output: "Test content",
      isRetry: ev.isRetry,
      commandId: (ev as PromptRequestEvent).prompt
        ? null
        : (ev as CommandExecuteEvent).command.id,
    };
    this.promptOutputs.unshift(newOutput as any);
  }
}
