<p-toast></p-toast>
<div class="layout-wrapper" [ngClass]="containerClass">
    <div *ngIf="!isProduction" class="layout-banner">
        <div class="font-bold mr-8">🚨 TEST ENVIRONMENT 🚨</div>
    </div>
    <app-topbar></app-topbar>
    <div class="layout-sidebar" [ngStyle]="!isProduction ? {'top': '11rem'} : {'top': '7rem'}">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container" [ngStyle]="!isProduction ? {'padding-top': '11rem'} : {'padding-top': '7rem'}">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <button class="chat-button p-button p-button-rounded" pTooltip="Chat with CentBot!" tooltipPosition="top" (click)="openDialog()">
            <i class="pi pi-comments"></i>
        </button>
        <app-footer></app-footer>
    </div>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>
