import { Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [MsalGuard],
        children: [
            { path: '', loadChildren: () => import('./home/routes').then(r => r.HOME_ROUTES) },
            { path: 'reports', loadChildren: () => import('./db-reports/routes').then(r => r.DB_REPORT_ROUTES) },
            { path: 'accounts', loadChildren: () => import('./customer-management/accounts/routes').then(r => r.ACCOUNTS_ROUTES) },
            { path: 'rtbf', loadChildren: () => import('./customer-management/rtbf/routes').then(r => r.RTBF_ROUTES) },
            { path: 'optiks/start', loadChildren: () => import('./optiks/start-workflow/routes').then(r => r.START_WORKFLOW_ROUTES) },
            { path: 'optiks/status', loadChildren: () => import('./optiks/workflow-status/routes').then(r => r.WORKFLOW_STATUS_ROUTES) },
            { path: 'items/tier-1', loadChildren: () => import('./items/tier-1-normalization/routes').then(r => r.TIER_1_NORMALIZATION_ROUTES) },
            { path: 'fs/search', loadChildren: () => import('./file-search/search/routes').then(r => r.SEARCH_ROUTES) },
            { path: 'fs/browser', loadChildren: () => import('./file-search/browser/routes').then(r => r.BROWSER_ROUTES) },
            { path: 'pulse', loadChildren: () => import('./pulse/dashboard/routes').then(r => r.PULSE_DASHBOARD_ROUTES) },
            { path: 'pulse/correlate', loadChildren: () => import('./pulse/correlate/routes').then(r => r.PULSE_CORRELATE_ROUTES) },
            { path: 'pulse/messages', loadChildren: () => import('./pulse/messages/routes').then(r => r.PULSE_MESSAGES_ROUTES) },
            { path: 'pulse/partners', loadChildren: () => import('./pulse/partners/routes').then(r => r.PULSE_PARTNERS_ROUTES) },
            { path: 'pulse/errors', loadChildren: () => import('./pulse/errors/routes').then(r => r.PULSE_ERRORS_ROUTES) },
            { path: 'troubleshooting/shipping-label', loadChildren: () => import('./troubleshooting/shipping-label/routes').then(r => r.SHIPPINGLABEL_ROUTES) },
            { path: 'cbdi', loadChildren: () => import('./cbdi/routes').then(r => r.CBDI_ROUTES) },
            { path: '**', pathMatch:'full', loadChildren: () => import('./not-found/routes').then(r => r.NOT_FOUND_ROUTES) }
        ]
    }
]
