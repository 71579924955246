import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  private baseUrl = environment.apiBaseUrl;

   
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Only prepend base URL for internal API calls
    if (request.url.startsWith('/api')) {
      const apiReq = request.clone({ url: `${this.baseUrl}${request.url}` });
      return next.handle(apiReq);
    }

    // For external requests, do not modify the request
    return next.handle(request);
  }
}
