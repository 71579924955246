import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  public readonly responses: Subject<string> = new Subject<string>();

  private chatbotApiUrl = '/api/chatbot';
  constructor(private http: HttpClient){}

  public sendQuestion(question: string): Observable<{ response: string }> {
    return this.http.post<{response: string}>(`${this.chatbotApiUrl}/chat/`, { question: question });
  }

}
