import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuChangeEvent } from '../api/menuchangeevent';
import { PrimeIcons } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}

  private menuSource = new Subject<MenuChangeEvent>();
  private resetSource = new Subject();

  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  menuModel = [
    {
      label: 'Home',
      expanded: true,
      items: [
        {
          label: 'Dashboard',
          icon: `pi pi-fw ${PrimeIcons.HOME}`,
          routerLink: ['/'],
        },
      ],
    },
    {
      label: 'Customer Management',
      expanded: false,
      items: [
        {
          label: 'Account Search',
          icon: `pi pi-fw ${PrimeIcons.SEARCH}`,
          routerLink: ['/accounts/search'],
        },
        {
          label: 'RTBF',
          icon: `pi pi-fw ${PrimeIcons.TIMES}`,
          routerLink: ['/rtbf'],
        },
      ],
    },
    {
      label: 'Database',
      expanded: false,
      items: [
        {
          label: 'Reports',
          icon: `pi pi-fw ${PrimeIcons.TABLE}`,
          routerLink: ['/reports'],
        },
      ],
    },
    {
      label: 'File Search',
      expanded: false,
      items: [
        {
          label: 'Search',
          icon: `pi pi-fw ${PrimeIcons.SEARCH}`,
          routerLink: ['/fs/search'],
        },
        {
          label: 'Browser',
          icon: `pi pi-fw ${PrimeIcons.FILE}`,
          routerLink: ['/fs/browser'],
        },
      ],
    },
    {
      label: 'Pulse',
      expanded: false,
      items: [
        {
          label: 'Dashboard',
          icon: `pi pi-fw ${PrimeIcons.CHART_BAR}`,
          routerLink: ['/pulse'],
        },
        {
          label: 'Correlate',
          icon: `pi pi-fw ${PrimeIcons.ARROW_RIGHT_ARROW_LEFT}`,
          routerLink: ['/pulse/correlate'],
        },
        {
          label: 'Logs',
          icon: `pi pi-fw ${PrimeIcons.FILE}`,
          routerLink: ['/pulse/messages'],
        },
        {
          label: 'Partners',
          icon: `pi pi-fw ${PrimeIcons.USERS}`,
          routerLink: ['/pulse/partners'],
        },
        {
          label: 'Customers',
          icon: `pi pi-fw ${PrimeIcons.SHOPPING_BAG}`,
          routerLink: ['/pulse/customers'],
        },
        {
          label: 'Errors',
          icon: `pi pi-fw ${PrimeIcons.TIMES_CIRCLE}`,
          routerLink: ['/pulse/errors'],
        },
      ],
    },
    {
      label: 'Customer Billing',
      expanded: false,
      items: [
        {
          label: 'Dashboard',
          icon: `pi pi-fw ${PrimeIcons.CHART_BAR}`,
          routerLink: ['/cbdi'],
        },
        {
          label: 'Companies Search',
          icon: 'pi pi-fw pi-search',
          routerLink: ['/cbdi/companies-search'],
        },
      ],
    },
    {
      label: 'Troubleshooting & Diagnosis',
      expanded: false,
      items: [
        {
          label: 'Lookup Order',
          icon: 'pi pi-fw pi-file',
          routerLink: ['/optiks/start'],
        },
        {
          label: 'Shipping Label',
          icon: 'pi pi-fw pi-tag',
          routerLink: ['/troubleshooting/shipping-label'],
        },
      ],
    },
    {
      label: 'Items tooling',
      expanded: false,
      items: [
        {
          label: 'Tier 1 Normalization',
          icon: `pi pi-fw ${PrimeIcons.WRENCH}`,
          routerLink: ['/items/tier-1'],
        },
      ],
    },
  ];

  onMenuStateChange(event: MenuChangeEvent) {
    this.menuSource.next(event);
  }
}
