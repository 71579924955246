<div class="layout-topbar">
  <button
    #menubutton
    class="p-link layout-menu-button layout-topbar-button"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi pi-bars"></i>
  </button>

  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/layout/images/{{
        layoutService.config.colorScheme === 'light'
          ? 'logo-dark'
          : 'logo-white'
      }}.png"
      alt="logo"
    />
    <span>CENTCOM</span>
    <span *ngIf="!isProd">&nbsp;(TEST ENV)</span>
  </a>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>
</div>
