<div class="layout-footer">
  <img
    src="assets/layout/images/{{
      layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'
    }}.png"
    alt="Logo"
    height="20"
    class="mr-2"
  />
  by
  <span class="font-medium ml-2">Rithum</span>
</div>
