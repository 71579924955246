import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(private authService: MsalService) {}

  ngOnInit() {
    this.authService.handleRedirectObservable().subscribe({
      next: (result) => {
        // Check if we got a response from the redirect
        if (result && result.account) {
          this.authService.instance.setActiveAccount(result.account);
        } else {
          // If there are no accounts, attempt to log in
          const accounts = this.authService.instance.getAllAccounts();
          if (accounts.length > 0) {
            this.authService.instance.setActiveAccount(accounts[0]);
          }
        }
      },
      error: (error) => console.error(error),
    });
  }
}
