<ul class="layout-menu">
  <ng-container *ngFor="let item of model; let i = index">
    <li
      *ngIf="!item.separator"
      class="menu-item"
      [class.expanded]="item.expanded"
    >
      <div class="menu-item-header" (click)="toggleSubmenu(item)">
        <i [class]="item.icon"></i>
        <span
          ><h6>{{ item.label }}</h6></span
        >
        <i
          *ngIf="item.items"
          class="pi"
          [ngClass]="{
            'pi-chevron-down': item.expanded,
            'pi-chevron-right': !item.expanded,
          }"
        ></i>
      </div>
      <ul *ngIf="item.items" class="submenu">
        <li *ngFor="let subItem of item.items" class="submenu-item">
          <a [routerLink]="subItem.routerLink">
            <i [class]="subItem.icon"></i>
            <span>{{ subItem.label }}</span>
          </a>
        </li>
      </ul>
    </li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
</ul>
